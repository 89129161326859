import axios from "axios";
import Cookie from "js-cookie";

export const navigationServices = {
  getGboundry,
  getArgo,
  getIrrigation,
  getTraps,
  getMachinesField,
  getTreeLogsTableData,
  getProductivity,
  getPetsAndDiseases,
  getSprouts,
  getMachinesTree,
  getFarm,
  getNumberOfTrees,
  getIrrigationNetwork,
  getSoildWork,
  getLogistics,
  getOthers,
  getNumberOfTreesIrrigation,
  getArgoTrees,
  getIrrigationTrees,
  getPetsAndDiseasesTrees,
  getSproutsTrees,
  getIrrigationNetworkTrees,
  getProductivityTrees,
  getLogisticsTrees,
  getNumberOfTreesPetsAndDiseases,
  getNumberOfTreesSprouts,
  getNumberOfTreesIrrigationNetwork,
  getNumberOfTreesProductivity,
  deleteLog,
  editLog,
  deleteTreeLog,
  deleteTrapLog,
};
async function getGboundry(id: string | number) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL + "gboundaries/" + id + "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getFarm(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL + "farms/" + id + "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getNumberOfTrees(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        "treeagro-activity-logs/more/" +
        id +
        "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getNumberOfTreesPetsAndDiseases(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        "tree-disease-activity-logs/more/" +
        id +
        "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getNumberOfTreesSprouts(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        "tree-sprout-activity-logs/more/" +
        id +
        "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getNumberOfTreesIrrigationNetwork(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        "tree-irrigation-network-activity-logs/more/" +
        id +
        "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getNumberOfTreesProductivity(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        "tree-harvest-activity-logs/more/" +
        id +
        "/",
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getNumberOfTreesIrrigation(submition_id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `treefield-irrigation-logs/more/${submition_id}/`,

      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
async function getArgo(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    if (fromTime !== "" || toTime !== "") {
      let res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/agro/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (res.status === 200) {
        return res;
      } else {
        return null;
      }
    } else {
      let res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/?field_id__id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (res.status === 200) {
        return res;
      } else {
        return null;
      }
    }
  } catch (e) {}
}

async function getArgoTrees(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",

  activity = "agro"
) {
  try {
    if (fromTime !== "" || toTime !== "") {
      let res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/${type}/${id}/${fromTime}/${toTime}/${activity}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (res.status === 200) {
        return res;
      } else {
        return null;
      }
    } else {
      let res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/?field_id__id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
      if (res.status === 200) {
        return res;
      } else {
        return null;
      }
    }
  } catch (e) {}
}

async function getIrrigation(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (type !== "" || fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treefield-irrigation-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getIrrigationTrees(
  id,
  fromTime = "",
  toTime = "",
  type = "trees"
) {
  try {
    let res;
    if (type !== "" || fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treefield-irrigation-logs/${type}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getSoildWork(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (type !== "" || fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/soil_work/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getIrrigationNetwork(
  id: number | string,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (type !== "" || fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `tree-irrigation-network-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getIrrigationNetworkTrees(
  id: number | string,
  fromTime = "",
  toTime = "",
  type = "fields"
) {
  try {
    let res;
    if (type !== "" || fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `tree-irrigation-network-activity-logs/${type}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getTraps(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `traps-logs/fields/${farm_id}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    } else {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `traps-logs/?trap_id__content_type__model=treefieldinfo&trap_id__object_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getPetsAndDiseases(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-disease-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getPetsAndDiseasesTrees(
  id,
  fromTime = "",
  toTime = "",
  type = "trees"
) {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-disease-activity-logs/${type}/${id}/${fromTime}/${toTime}/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getSprouts(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-sprout-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getSproutsTrees(id, fromTime = "", toTime = "", type = "trees") {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-sprout-activity-logs/${type}/${id}/${fromTime}/${toTime}/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}

async function getProductivity(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-harvest-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/productivity/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getProductivityTrees(
  id,
  fromTime = "",
  toTime = "",
  type = "trees"
) {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `tree-harvest-activity-logs/${type}/${id}/${fromTime}/${toTime}/productivity/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getMachinesField(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `machinery-usage-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getOthers(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `treeagro-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/others/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getLogistics(
  id,
  fromTime = "",
  toTime = "",
  type = "fields",
  farm_id
) {
  try {
    let res;
    if (fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `logistics-activity-logs/${type}/${farm_id}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getLogisticsTrees(
  id,
  fromTime = "",
  toTime = "",
  type = "fields"
) {
  try {
    let res;
    if (fromTime !== "" || toTime !== "") {
      res = await axios.get(
        process.env.NEXT_PUBLIC_BASE_URL +
          `logistics-activity-logs/${type}/${id}/${fromTime}/${toTime}/`,
        {
          headers: {
            Authorization: `Bearer ${Cookie.get("token")}`,
          },
        }
      );
    }
    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getMachinesTree(id, fromTime = "", toTime = "", type = "trees") {
  try {
    let res;
    res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `machinery-usage-log/${type}/${id}/${fromTime}/${toTime}/`,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (e) {}
}
async function getTreeLogsTableData(id) {
  try {
    let res = await axios.get(
      process.env.NEXT_PUBLIC_BASE_URL +
        `treefield-irrigation-logs/?field_id__id=${id}`,

      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    return res;
  } catch (e) {}
}
async function deleteLog(id, category, field_id, farm_id) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + `${category}/delete-logs/`,
      { submission_id: id, field_id: field_id, farm_id: farm_id },
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}
async function deleteTrapLog(id, category, field_id, farm_id, trap_id) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + `${category}/delete-logs/`,
      {
        submission_id: id,
        field_id: field_id,
        farm_id: farm_id,
        trap_id: trap_id,
      },
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}
async function deleteTreeLog(id, category, field_id, tree_id) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + `${category}/delete-logs/`,
      { submission_id: id, field_id: field_id, tree_id: tree_id },
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}

async function editLog(data, category) {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + `${category}/edit-logs/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );

    if (res.status === 200) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}
